import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"

export default props => {
  const { twitter, instagram } = props

  return (
    <div className="mr-3 flex flex-row">
      <a href={`https://twitter.com/${twitter}`} target='_blank' rel='noopener noreferrer'>
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      {instagram ? (
        <a className="ml-2" href={`https://instagram.com/${instagram}`} target='_blank' rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      ) : null}
    </div>
  )
}
