import React from "react"
import { baseUrl } from "../../services/constants"
import UserSocialMedia from "./UserSocialMedia"

export default props => {
  const { post } = props

  const avatarUrl =
    post.user.avatar && post.user.avatar.url && post.user.avatar.url.startsWith("http")
      ? post.user.avatar.url
      : baseUrl + post.user.avatar.url

  return (
    <div className='flex items-center'>
      <img
        className='rounded-full h-12 w-12 object-cover'
        src={avatarUrl}
        alt={post.user.username}
      /> 
      <div className='ml-4 text-gray-800 flex flex-col'>
        <div className="flex">
          <p className='font-semibold mr-2'>{post.user.username}</p>
          <UserSocialMedia twitter={post.user.twitter} instagram={post.user.instagram} />
        </div>
        <p className='text-sm'>{post.user.biography}</p>
      </div>
    </div>
  )
}
