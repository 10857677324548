import React, { Fragment } from "react";
import { format } from "date-fns";
import Tags from "./Tags";
import UserSocialMedia from "./UserSocialMedia";

export default props => {
  const { post } = props;

  const filteredTags = post.tags.split(',').filter(t => t !== 'blog');
  return (
    <Fragment>
      <div className='flex'>
        <div className='mt-4 mb-2 flex'>
          <UserSocialMedia twitter={post.user.twitter} instagram={post.user.instagram} />
          <span>{post.user.username}</span>
        </div>
      </div>

      <div className='mt-2'>
        <Tags tags={filteredTags} />
      </div>
    </Fragment>
  );
};
