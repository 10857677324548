import React from "react"

export default props => {
  const { tags } = props
  return (
    <div className='flex flex-wrap'>
      {tags.map((tag, index) => {
        return (
          <span
            key={index}
            to='/'
            className='rounded break-words bg-gray-300 text-gray-800 text-xs py-1 px-2'
            style={{
              margin: `0.25rem 0.25rem`
            }}>
            {tag}
          </span>
        )
      })}
    </div>
  )
}
