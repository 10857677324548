import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PostMetadata from "../components/blog/PostMetadata";
import Author from "../components/blog/Author";
import ServiceLayout from "../components/ServiceLayout";
import ArticleSelfAd from "../components/blog/ArticleSelfAd";
import { Helmet } from "react-helmet";
import * as showdown from "showdown";
import { baseUrl } from "../services/constants";
import "../styles.css";

const ArticleTemplate = ({ data }) => {
  const article = data.strapiArticle;
  let parsedContentAsHtml = null;

  if (article.richContent) {
    parsedContentAsHtml = new showdown.Converter().makeHtml(article.richContent);
  } else {
    parsedContentAsHtml = article.content;
  }

  const articleMetaDescription = article.content
    ? article.content.substring(0, 160)
    : article.richContent.substring(0, 160);

  const titleComponent = (
    <div className='my-6 md:my-12 rounded p-6'>
      <h1 className='font-semibold text-2xl md:text-4xl mt-2 lg:mt-8 text-gray-800'>
        {article.title}
      </h1>
      <div className='text-sm md:text-xl text-gray-700'>
        <PostMetadata post={article} compact />
      </div>
    </div>
  );

  const content = (
    <>
      <div className='h-64'>
        <img
          className='w-full h-full object-cover rounded shadow-md'
          src={article.featuredImage.url}
        />
      </div>

      <div className='md:max-w-3xl mt-4 px-4 py-1 mx-auto rounded '>
        <div
          className='article-content my-8'
          dangerouslySetInnerHTML={{ __html: parsedContentAsHtml }}
        />
        <Author post={article} />
        <div className='mt-6 flex flex-col items-center justify-center'>
          <div className='bg-gray-300 h-px m-4' />
          <ArticleSelfAd hideShadow />
        </div>
      </div>
    </>
  );

  return (
    <ServiceLayout titleComponent={titleComponent} hideContactUs>
      <Helmet>
        <title>{article.title} | Red Brick Road Studio</title>
        <link rel='canonical' href={`${article.fields.slug}`} />
        <meta name='twitter:title' content={article.title} />
        <meta name='description' content={articleMetaDescription} />
        <meta name='twitter:description' content={articleMetaDescription} />

        <meta name='keywords' content={article.tags} />
        {/* {article.featuredImage ? (
          // need to update the content link
          // <meta
          //   name='twitter:image'
          //   content={`${baseUrl}${article.featuredImage.childImageSharp.fluid.src}`}
          // />
        ) : null} */}
      </Helmet>
      {content}
    </ServiceLayout>
  );
};

export default ArticleTemplate;

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: { eq: $id }) {
      title
      content
      featuredImage {
        url
      }
      fields {
        slug
      }
      richContent
      tags
      updated_at
      created_at
      user {
        id
        username
        avatar {
          url
        }
        twitter
        biography
      }
    }
  }
`;
