import React from "react";
import { ContactUsButton } from "../ContactUsButton";

export default props => {
  const { compact } = props;
  return (
    <div className={`py-8 text-gray-800 rounded p-4 flex justify-between items-center`}>
      <div className="flex-col items-center justify-center text-center">
        <p className='font-medium text-xl md:text-2xl mb-2'>Enjoy our writing?</p>
        <p className='mb-2 text-lg md:text-xl'>We do professional copywriting and editing!</p>
        <div className='mt-4 sm:mt-8'>
          <ContactUsButton actionText='Contact us to get started' compact />
        </div>
      </div>
    </div>
  );
};
